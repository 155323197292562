import 'animate.css';
import React, { useEffect, useState } from 'react';
import './styles/Home.css';
import './styles/index.css';
import eldritch from './images/eldritch.png';
import tree from './images/tree.png'

function Hope() {

    const [loading, setLoading] = useState(true);
    const [toggleEffect, setToggleEffect] = useState(true);
    const [seeMore, setSeeMore] = useState(false);

    const loadData = async () => {
        await new Promise((r) => setTimeout(r, 1000));
        setLoading((loading) => !loading);
    };

    useEffect(() => {
        loadData();
    }, [])

    const handleClickSeeMore = () => {
        setSeeMore(!seeMore)
    }

    if (loading) {
        return (
            <div className='homepage crt'>
                <h3 className='loadingInterlude'>LOADING...</h3>
            </div>
        )
    } else {
        return (
            <div className={toggleEffect ? 'homepage crt' : 'homepage'}>
                <div className='submenu-about scroll'>
                    <h3 className='header-home'>**** EDGETECH.INDUSTRIES ****</h3>
                    <div>
                        <div className='eldritch-container'>
                            <span className='eldritch-title'>THE LAST HOPE</span>
                            <br />
                            <p className='eldritch-subtitle'>
                            ** CLASSIFIED INFORMATION **
                            </p>
                            <br />
                            The Tree of Life... on this wasteland of a planet.
                            <br/><br/>
                            Any one of our guys get close to it, they get fuckin&apos; vaporized.
                            <br/><br/>
                            Sure is a sight to see, though. The kind of stuff our kids only hear about in fairy tales.
                            <br/><br/>
                            There&apos;s got to be a way to get through to them. This can&apos;t be the end for us, not after everything we&apos;ve gone through.
                            <br/><br/>
                            There&apos;s got to be hope...
                            <br/><br/>
                            ...Right?
                            <br/><br/>
                            **** END OF TRANSCRIPT ****
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex'}}>
                    <button className='buttonBackToMenu' onClick={() => window.location.replace(`/archive`)}>ARCHIVE MENU</button>
                </div>
            </div>
        )
    }
}

export default Hope;
