import 'animate.css';
import React, { useEffect, useState } from 'react';
import { Link, Redirect, Router, useNavigate } from 'react-router-dom';
import './styles/Home.css';
import './styles/index.css';

function SoonTM() {

    const [loading, setLoading] = useState(true)

    const loadData = async () => {
        await new Promise((r) => setTimeout(r, 1000));
        setLoading((loading) => !loading);
    };

    const yesToMenu = () => {
      const { href } = window.location;
      window.location.href = `/`;
    }

    const noToMenu = () => {
      const { href } = window.location;
      window.location.href = `/`;
    }

    useEffect(() => {
        loadData();
        function handleKeyDown(e) {
            if (e.keyCode == 89) {
                const { href } = window.location;
                window.location.href = `/`;

            } else if (e.keyCode == 78) {
                const { href } = window.location;
                window.location.href = `/`;
            };
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [])


    const toMenu = () => {
      const { href } = window.location;
      window.location.href = `/`;
    }


    if (loading) {
        return (
            <div className='homepage crt'>
                <h3 className='loadingInterlude'>LOADING...</h3>
            </div>
        )
    } else {
        return (
            <div className='homepage crt'>
                <div className='submenu'>
                    <h3 className='header-home'>**** elDriTcH.INDUSTRIES ****</h3>
                    <div>
                        <br/><br/>
                        <div className='css-typing'>
                        <p>pAtiEnCe..................................</p>
                        <p>thE tImE iS nOt uPoN Us...................</p>
                        <p>pAy clOsE aTtEnTiOn aNd YoU wIlL kNoW.....</p>
                        <p>w<span className='white'>E</span>&apos;rE <span className='white'>t</span>rAnsCEndInG <span className='white'>H</span>eLl iTselF</p>
                        <p>..........................................</p>
                        <p>lOnG lIVe thE gReaT ForESt</p>
                        <p>*************END*TRANSMISSION**************</p>
                    </div>
                    </div>
                </div>
                <div style={{ display: 'flex'}}>
                    <button className='buttonBackToMenu' onClick={toMenu}>HOME</button>
                </div>
            </div>
        )
    }
}

export default SoonTM;
