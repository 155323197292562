const characters = [
    'THE ELDRITCH PROJECT',
    'THE LAST HOPE',
    'THE PLAN',
    'FOOTAGE',
    'COSMOS',
    'FUTURE',
    'FINAL ATTACHMENTS',
    'MAIN MENU',
];

export default characters;
