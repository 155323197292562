import 'animate.css';
import React, { useEffect, useState } from 'react';
import './styles/Home.css';
import './styles/index.css';
import sample01 from './images/sample01.png';
import sample02 from './images/sample02.png';
import sample03 from './images/sample03.png'

function Contact() {

    const [loading, setLoading] = useState(true);
    const [toggleEffect, setToggleEffect] = useState(true);
    const [seeMore, setSeeMore] = useState(false);

    const loadData = async () => {
        await new Promise((r) => setTimeout(r, 1000));
        setLoading((loading) => !loading);
    };

    useEffect(() => {
        loadData();
    }, [])

    const handleClickSeeMore = () => {
        setSeeMore(!seeMore)
    }

    if (loading) {
        return (
            <div className='homepage crt'>
                <h3 className='loadingInterlude'>LOADING...</h3>
            </div>
        )
    } else {
        return (
            <div className={toggleEffect ? 'homepage crt' : 'homepage'}>
                <div className='submenu-about scroll'>
                    <h3 className='header-home'>**** EDGETECH.INDUSTRIES ****</h3>
                        <div className='eldritch-container'>
                            <span className='eldritch-title'>THE PLAN</span>
                            <br />
                            <p className='eldritch-subtitle'>
                            ** CLASSIFIED INFORMATION **
                            </p>
                            <br />
                            Futile as it may be, I have devised a plan.
                            <br/><br/>
                            Many hours have been spent watching archived footage, analyzing every single glimpse of THE ELDRITCH I could find.
                            <br/><br/>
                            I have taken to the pen, scribing down every physical trait I see. Every characteristic. Every piece of personality these little creatures possess. It has taken copious amounts of time, but perhaps with this data we can train
                            THE ARCHITECT to create unique versions of THE ELDRITCH to aide our attempts at communication. Through our holodecks, we can interact with the generated ELDRITCH peacefully, and show these creatures that we mean them no harm.
                            We can show them that we can live in peace and pose no threat to their existence.
                            <br/><br/>
                            THE ARCHITECT will develop holodeck TOKENS for each person with HASHES that grant access to leave the compound. All you need to do is claim your TOKEN once THE ARCHITECT is finished composing.
                            <br/><br/>
                            Anyone with the gall to attempt contact with these creatures should do so with your holodeck enabled and your TOKEN on active display. Hoping here that THE ELDRITCH will see you as friend over foe, and perhaps we can gain some ground
                            in developing a less hostile relationship.
                            <br/><br/>
                            I have uploaded some references for the archives.
                            <br/><br/>
                            Do so, obivously, at your own risk. Futile as it may be.

                        <div className='see-more' onClick={() => handleClickSeeMore()}>
                            {seeMore ?
                                <div>
                                    <p>** CLOSE ATTACHMENTS **</p>
                                    <img src={sample01} alt="" style={{ width: '200px', justifyContent: 'left' }} />
                                    <img src={sample02} alt="" style={{ width: '200px', justifyContent: 'left' }} />
                                    <img src={sample03} alt="" style={{ width: '200px', justifyContent: 'left' }} />
                                </div>
                                :
                                <p>** VIEW ATTACHMENTS **</p>}
                        </div>
                          <div className="divider"/>
                        </div>
                        </div>
                        <div style={{ display: 'flex'}}>
                            <button className='buttonBackToMenu' onClick={() => window.location.replace(`/archive`)}>ARCHIVE MENU</button>
                        </div>
                        <div className="divider"/>
            </div>
        )
    }
}

export default Contact;
