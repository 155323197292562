import 'animate.css';
import React, { useEffect, useState } from 'react';
import { Link, Redirect, Router, useNavigate } from 'react-router-dom';
import './styles/Home.css';
import './styles/index.css';

function Home() {

    const [loading, setLoading] = useState(true)

    const loadData = async () => {
        await new Promise((r) => setTimeout(r, 1000));
        setLoading((loading) => !loading);
    };

    const yesToMenu = () => {
      const { href } = window.location;
      window.location.href = `/home2`;
    }

    const noToMenu = () => {
      const { href } = window.location;
      window.location.href = `/no`;
    }

    const RedirectPage = () => {
        window.open('https://ftmscan.com/token/0xe12e130fa7836b870c63d6b7d511a1c4fdc4c8ca', '_blank');
      }

    const SecondaryRedirect = () => {
        window.open('https://snowtrace.io/token/0x0d34bfef75ac022c1e8f306daacd9c903546d33a', '_blank');
      }

    const TertiaryRedirect = () => {
        window.open('https://snowtrace.io/token/0x0d34bfef75ac022c1e8f306daacd9c903546d33a', '_blank');
    }

    useEffect(() => {
        loadData();
        function handleKeyDown(e) {
            if (e.keyCode == 89) {
                const { href } = window.location;
                window.location.href = `/home2`;

            } else if (e.keyCode == 78) {
                const { href } = window.location;
                window.location.href = `/no`;
            };
        }

        document.addEventListener('keydown', handleKeyDown);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [])


    const toMenu = () => {
      const { href } = window.location;
      window.location.href = `/archive`;
    }


    if (loading) {
        return (
            <div className='homepage crt'>
                <h3 className='loadingInterlude'>LOADING...</h3>
            </div>
        )
    } else {
        return (
            <div className='homepage crt'>
                <div className='submenu'>
                    <h3 className='header-home'>**** EDGETECH.INDUSTRIES ****</h3>
                    <div>
                        <br/><br/>
                        <div className='css-typing'>
                        <p>Initializing system...................DONE</p>
                        <p>Inbound transmission from THE ARCHITECT...</p>
                        <p>Unique token identifier required from HASH:</p>
                        <p><div className="yes-button" role="button" onClick={RedirectPage}>0xe12E130fA7836b870C63D6B7d511a1c4fdc4C8ca</div></p>
                        <p>..........................................</p>
                        <p>Secondary HASH accepted by local node.....</p>
                        <p><div className="yes-button" role="button" onClick={SecondaryRedirect}>0x0d34bfef75ac022c1e8f306daacd9c903546d33a</div></p>
                        <p>Proper identification required for access.</p>
                    </div>
                    </div>
                    <p className='run animate__animated animate__fadeIn animate__delay-40s'>
                    OPEN MESSAGE?(<div className="yes-button" role="button" onClick={yesToMenu}>y</div>/<div className="yes-button" role="button" onClick={noToMenu}>n</div>)
                    </p>

                </div>
                <div style={{ display: 'flex'}}>
                    <button className='buttonBackToMenu' onClick={toMenu}>SKIP</button>
                </div>
            </div>
        )
    }
}

export default Home;
