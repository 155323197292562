import 'animate.css';
import React, { useEffect, useState } from 'react';
import './styles/Home.css';
import './styles/index.css';
import eldritch from './images/eldritch.png';
import eldritch1 from './images/001.png';
import eldritch2 from './images/002.png';
import eldritch3 from './images/003.png';
import tree from './images/tree.png'

function Footage() {

    const [loading, setLoading] = useState(true);
    const [toggleEffect, setToggleEffect] = useState(true);
    const [seeMore, setSeeMore] = useState(false);

    const loadData = async () => {
        await new Promise((r) => setTimeout(r, 1000));
        setLoading((loading) => !loading);
    };

    useEffect(() => {
        loadData();
    }, [])

    const handleClickSeeMore = () => {
        setSeeMore(!seeMore)
    }

    if (loading) {
        return (
            <div className='homepage crt'>
                <h3 className='loadingInterlude'>LOADING...</h3>
            </div>
        )
    } else {
        return (
            <div className={toggleEffect ? 'homepage crt' : 'homepage'}>
                <div className='submenu-about scroll'>
                    <h3 className='header-home'>**** EDGETECH.INDUSTRIES ****</h3>
                    <div>
                        <div className='eldritch-container'>
                            <span className='eldritch-title'>FOOTAGE</span>
                            <br />
                            <p className='eldritch-subtitle'>
                            ** CLASSIFIED INFORMATION **
                            </p>
                            <br />
                            Like most expeditions to the ELDRITCH crater, our drones were destroyed almost instantly upon discovery. This time, however, we received a transmission
                            from Viper XJ9-CS14 shorlty before it permanently went offline.
                            <br/><br/>
                            Amazingly, we have received imagery of THE ELDRITCH! Throughout the dozens of failed attempts at catching glipse of our strange new nemesis, we finally have something
                            tangible to study.
                            <br/><br/>
                            Sadly, THE ARCHITECT cannot decipher any details around them. Everything about these creatures is unknown to all of our databases.
                            <br/><br/>
                            What a sight, though.
                            <br/><br/>
                            <div className='see-more' onClick={() => handleClickSeeMore()}>
                                {seeMore ?
                                    <div>
                                        <p>** CLOSE ATTACHMENTS **</p>
                                        <img src={eldritch1} alt="eldritch rising" style={{ width: '200px', justifyContent: 'center', paddingRight: '20px'}} />
                                        <img src={eldritch2} alt="eldritch rising sprite" style={{ width: '200px', justifyContent: 'center', paddingRight: '20px'  }} />
                                        <img src={eldritch3} alt="eldritch rising fantom" style={{ width: '200px', justifyContent: 'center', paddingRight: '20px' }} />
                                    </div>
                                    :
                                    <p>** VIEW ATTACHMENTS **</p>}
                            </div>
                            <div className="divider"/>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex'}}>
                    <button className='buttonBackToMenu' onClick={() => window.location.replace(`/archive`)}>ARCHIVE MENU</button>
                </div>
            </div>
        )
    }
}

export default Footage;
