import 'animate.css';
import React, { useEffect, useState } from 'react';
import { Nav, NavLink, NavItem } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';
import './styles/Home.css';
import './styles/index.css';
import List from './ArchiveList';



function ArchiveMenu() {

    const [loading, setLoading] = useState(true);
    const [toggleEffect, setToggleEffect] = useState(true)


    useEffect(() => {
        const loadData = async () => {
            await new Promise((r) => setTimeout(r, 500));
            setLoading((loading) => !loading);
        };
        loadData();
    }, [])



    if (loading) {
        return (
            <div className='homepage crt'>
                <h3 className='loadingInterlude'>LOADING...</h3>
            </div>
        )
    } else {
        return (
            <div className={toggleEffect ? 'homepage crt' : 'homepage'}>
                <List />
            <br/><br/>
            </div>
        )
    }
}

export default ArchiveMenu;
