import 'animate.css';
import React, { useEffect, useState } from 'react';
import './styles/Home.css';
import './styles/index.css';
import eldritch from './images/eldritch.png';
import tree from './images/tree.png'

function Eldritch() {

    const [loading, setLoading] = useState(true);
    const [toggleEffect, setToggleEffect] = useState(true);
    const [seeMore, setSeeMore] = useState(false);

    const loadData = async () => {
        await new Promise((r) => setTimeout(r, 1000));
        setLoading((loading) => !loading);
    };

    useEffect(() => {
        loadData();
    }, [])

    const handleClickSeeMore = () => {
        setSeeMore(!seeMore)
    }

    if (loading) {
        return (
            <div className='homepage crt'>
                <h3 className='loadingInterlude'>LOADING...</h3>
            </div>
        )
    } else {
        return (
            <div className={toggleEffect ? 'homepage crt' : 'homepage'}>
                <div className='submenu-about scroll'>
                    <h3 className='header-home'>**** EDGETECH.INDUSTRIES ****</h3>
                    <div>
                        <div className='eldritch-container'>
                            <span className='eldritch-title'>THE ELDRITCH PROJECT</span>
                            <br />
                            <p className='eldritch-subtitle'>
                            ** CLASSIFIED INFORMATION **
                            </p>
                            <br />
                            30 years ago, a monstrosity descended the heavens and eradicated mankind. The collision was unlike anything we could
                            have imagined, and almost all of our preparations were entirely futile. Handfuls of people survived thanks to self-sustaining
                            bunkers deep underground left over from the last World War, but I fear our time is now limited.
                            <br/><br/>
                            The event was inexplicable. When the meteor pierced through the clouds, it was obvious that it was no rock. It was almost as
                            if it was a living, breathing lifeform -- fluid with bright purple waves that could only be called &apos;magical&apos;.
                            Ironic, isn&apos;t it?
                            <br/><br/>
                            Drones monitoring surface activity provided even more questions. While our planet had already been on the brink of demise, it appeared
                            that the point of impact had become a source of incredible organic growth. It was the first time I had seen plants growing
                            naturally in the earth in my entire life. It was the most amazing sight to behold.
                            <br/><br/>
                            Sadly, the surface was inhospitable. Each expedition ended in annihilation with entire teams of men eradicated under the most
                            curious circumstances. We dubbed it &apos;THE MIST&apos; -- an organism, quite obviously not of this planet, with the ability to literally
                            consume anything in encapsulates. No technological advances could provide any strategy against this crisis, no peace as we watch our race go extinct.
                            <br/><br/>
                            Within the crater where the calamity struck, there now stands the most incredible tree - it&apos;s rapid growth as unnatural as
                            it is beautiful. Considering the changes occurring on the surface, I felt it only proper to name it &apos;THE TREE OF LIFE&apos;.
                            We would start our attempts to uncover the source of THE MIST here.
                            <br/><br/>
                            Our drones have found evidence of lifeforms not native to our planet. Unfortunately, we have yet to obtain a proper visual, but I
                            have logged the best we have obtained thus far. They&apos;re quite peculiar creatures... I call them THE ELDRITCH. We are attemping to
                            utilize our AI networks to identify effective means of communication, but I fear our time is running thin. Life-maintaining systems within our bunkers have been acting erratic,
                            and TECHNICIANS are hard to come by these days. Call me crazy, but I think THE ELDRITCH are somehow behind these system failures.
                            <br/><br/>
                            **** CORRUPT DATA - CANNOT RECOVER REST OF TRANSCRIPT ****

                            <div className='see-more' onClick={() => handleClickSeeMore()}>
                                {seeMore ?
                                    <div>
                                        <p>** CLOSE ATTACHMENTS **</p>
                                        <img src={eldritch} alt="" style={{ width: '200px', justifyContent: 'left' }} />
                                        <img src={tree} alt="" style={{ width: '200px', justifyContent: 'left' }} />
                                    </div>
                                    :
                                    <p>** VIEW ATTACHMENTS **</p>}
                            </div>
                                                <div className="divider"/>
                        </div>
                    </div>


                </div>
                <div style={{ display: 'flex'}}>
                    <button className='buttonBackToMenu' onClick={() => window.location.replace(`/archive`)}>ARCHIVE MENU</button>
                </div>
                <div className="divider"/>
            </div>
        )
    }
}

export default Eldritch;
