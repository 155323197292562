import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import video from '../video/02.mp4';
import '../styles/Home.css';

class Video2 extends Component {
    render () {
        return (
        <div className='player'>
            <ReactPlayer
            className='react-player'
            url= {video}
            width='25%'
            height='25%'
            controls = {true}
            />
        </div>
        )
    }
}

export default Video2;
