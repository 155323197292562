import React from 'react';
import Home from './Home';
import Home2 from './Home2';
import NoScreen from './NoScreen';
import Menu from './MenuScreen';
import Eldritch from './Eldritch';
import Hope from './Hope';
import Contact from './Contact';
import Game from './Game';
import Cosmos from './Cosmos';
import Footage from './Footage';
import Open from './Open';
import ArchiveMenu from './Archive';
import Open3 from './Open3';
import Theater from './theater';
import SoonTM from './soonTM';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    return (
        <Router>

            <Switch>
                <Route exact path="/" component={Menu} />
                <Route path="/no" component={NoScreen} />
                <Route path="/menu" component={Menu} />
                <Route path="/eldritch" component={Eldritch} />
                <Route path="/hope" component={Hope} />
                <Route path="/contact" component={Contact} />
                <Route path="/game" component={Game} />
                <Route path="/cosmos" component={Cosmos} />
                <Route path="/footage" component={Footage} />
                <Route path="/Open" component={Open} />
                <Route path="/Open3" component={Open3} />
                <Route path="/theater" component={Theater} />
                <Route path="/soonTM" component={SoonTM} />
                <Route path="/archive" component={ArchiveMenu} />
                <Route path="/home" component={Home} />
                <Route path="/home2" component={Home2} />
            </Switch>

        </Router>
    );
}

export default App;
