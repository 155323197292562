import 'animate.css';
import React, { useEffect, useState } from 'react';
import { Link, Redirect, Router, useNavigate } from 'react-router-dom';
import './styles/Home.css';
import './styles/index.css';

function Home2() {

    const [loading, setLoading] = useState(true)

    const loadData = async () => {
        await new Promise((r) => setTimeout(r, 1000));
        setLoading((loading) => !loading);

    const yesToMenu = () => {
    const { href } = window.location;
    window.location.href = `/archive`;
        }
    };

    useEffect(() => {
        loadData();
    }, [])

    const toMenu = () => {
      const { href } = window.location;
      window.location.href = `/archive`;
    }

    if (loading) {
        return (
            <div className='homepage crt'>
                <h3 className='loadingInterlude'>LOADING...</h3>
            </div>
        )
    } else {
        return (
            <div className='homepage crt'>
                <div className='submenu'>
                    <h3 className='header-home'>**** EDGETECH.INDUSTRIES ****</h3>
                    <div>
                        <br/><br/>
                        <div className='css-typing'>
                        <p>Downloading...............................</p>
                        <p>ANOMOLY DETECTED. CRITICAL ERROR..........</p>
                        <p>Attempting OS recovery....................</p>
                        <p>Image found. Initializing.................</p>
                        <p>Generating completion approximation.......</p>
                        <p><div className="yes-button" role="button" onClick={toMenu}>SAFE MODE is now available................</div></p>
                    </div>
                    </div>
                </div>
                <div style={{ display: 'flex'}}>
                <div className="buttonWeb3" onClick={toMenu}>MENU</div>
                </div>
            </div>
        )
    }
}

export default Home2;
