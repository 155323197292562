import React from "react";
import './styles/Home.css';
import Item from "./Item";
import useRoveFocus from "./useRoveFocus";
import menu from "./menu";

const List = () => {
    const [focus, setFocus] = useRoveFocus(menu.length);

    return (
        <div className='submenu-home'>
            <h3 className='header-home'>**** EDGETECH.INDUSTRIES ****</h3>
            <nav className='flex'>
                <ul className='menu2'>
                    {menu.map((menuItem, index) => (
                        <Item
                            key={menu}
                            setFocus={setFocus}
                            index={index}
                            focus={focus === index}
                            character={menuItem}
                            className='homelink'
                        />
                    ))}
                </ul>
            </nav>
            <p className='header-home2'>KEYBOARD ACTIONS ENABLED</p>
        </div>
    );
};

export default List;
