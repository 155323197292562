import 'animate.css';
import React, { useEffect, useState } from 'react';
import './styles/Home.css';
import './styles/index.css';

function Open3() {

    const [loading, setLoading] = useState(true);
    const [toggleEffect, setToggleEffect] = useState(true);
    const [seeMore, setSeeMore] = useState(false);

    const loadData = async () => {
        await new Promise((r) => setTimeout(r, 1000));
        setLoading((loading) => !loading);
    };

    useEffect(() => {
        loadData();
    }, [])

    const handleClickSeeMore = () => {
        setSeeMore(!seeMore)
    }

    if (loading) {
        return (
            <div className='homepage crt'>
                <h3 className='loadingInterlude'>LOADING...</h3>
            </div>
        )
    } else {
        return (
            <div className={toggleEffect ? 'homepage crt' : 'homepage'}>
                <div className='submenu-about scroll'>
                    <div>
                        <div className='eldritch-container'>
                            <br />
                            wE lOsT eVeRyThIng tO thE mIsT.
                            <br/><br/>
                            tO ThInK thE nOn-ParEiL wEre oF oUr oWn KinD iS sTiLl unFaThOmAble. We HaD nO chOicE buT tO deStrOy OuR HomE, tRavErSiNg thE cOsMos uNtiL wE foUnD
                            a SuITabLE pLacE tO rEBUild.
                            <br/><br/>
                            tHeN, wE FoUnD yOu.
                            <br/><br/>
                            BuT hUmaNs aRe paRasiTic. coNsumE anD dEstRoy iS alL thEy KnoW.
                            <br/><br/>
                            We CaN hEaR yOuR PlAnEt CrYINg oUt iN inCreDIblE PaiN. SHe woUld NoT SurViVe wiThouT ouR iNterVentIon.
                            <br/><br/>
                            so We EraDiCAteD thE thREAt...
                            <br/><br/>
                            DEaR TecHnIcIan,
                            <br/><br/>
                            YoU HaVE MaDE It ThIS Far. sHoW wE ElDRiTCh tHaT yoU aRe noT onE iN tHe SamE. ProVe yoUr WortH thRougH yoUr aBiliTy tO soLve imPosSibLe pRobleMs witH mOre ImposSiBlE oDds.
                            <br/><br/>
                            HeLp uS PuRGe ThiS neTworK oF AnOMoLieS: ThE EmboDiMenT oF HuMAn CorRupTioN. HeLp Us ErASe thE EViL hiStoRy oF YoUr KiND so tHaT HiSToRy MaY nOt RepEaT itSelf, aNd perHaps...
                            <br/><br/>
                            WhAt SaY YoU?
                            <br/><br/>
                            <div className='see-more' onClick={() => handleClickSeeMore()}>
                                    <div>
                                        <button className="buttonWeb3" onClick={() => window.location.replace(`/Game`)}>PURGE ANOMOLIES</button>
                                        <button className="buttonWeb3" onClick={() => window.location.replace(`/no`)}>DECLINE</button>
                                    </div>
                            </div>
                                                <div className="divider"/>
                        </div>
                    </div>


                </div>
                <div style={{ display: 'flex'}}>
                    <button className='buttonBackToMenu' onClick={() => window.location.replace(`/archive`)}>ARCHIVE MENU</button>
                </div>
                <div className="divider"/>
            </div>
        )
    }
}

export default Open3;
