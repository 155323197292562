import 'animate.css';
import React, { useEffect, useState } from 'react';
import './styles/Home.css';
import './styles/index.css';
import ftm from './images/ftm.png';
import avax from './images/avax.png';
import eth from './images/eth.png';
import hbar from './images/hbar.png';
import plant01 from './images/plant01.png';
import plant02 from './images/plant02.png';
import plant03 from './images/plant03.png';
import lovers from './images/lovers.png';
import oracle from './images/oracle.png';
import scholar from './images/scholar.png';
import necromancer from './images/necromancer.png';
import guardian from './images/guardian.png';
import follower from './images/follower.png';
import chaos from './images/chaos.png';
import genesis from './images/genesis.png';
import keeper from './images/keeper.png';
import Video1 from './components/video1';
import Video2 from './components/video2';
import Video3 from './components/video3';
import Making1 from './components/making1';
import Making2 from './components/making2';
import Making3 from './components/making3';
import Comfort from './components/comfort';

function Theater() {

    const [loading, setLoading] = useState(true);
    const [toggleEffect, setToggleEffect] = useState(true);
    const [seeMore, setSeeMore] = useState(false);

    const loadData = async () => {
        await new Promise((r) => setTimeout(r, 1000));
        setLoading((loading) => !loading);
    };

    useEffect(() => {
        loadData();
    }, [])

    const handleClickSeeMore = () => {
        setSeeMore(!seeMore)
    }

    if (loading) {
        return (
            <div className='homepage crt'>
                <h3 className='loadingInterlude'>LOADING...</h3>
            </div>
        )
    } else {
        return (
            <div className={toggleEffect ? 'homepage crt' : 'homepage'}>
                <div className='submenu-theater scroll'>
                    <h3 className='header-home'>**** EDGETECH.INDUSTRIES ****</h3>
                    <div>
                            <br />
                                    <div>
                                        <p className='theater-subtitle'>
                                        The First Trailer (with <a className="link" href="https://twitter.com/milesmuso" target={"_blank"} rel={"noopener noreferrer"}>@milesmuso</a>)</p>
                                        <Video1/>
                                        <div className="divider"/>
                                        <p className='theater-subtitle'>
                                        The Second Trailer (with Zaek, Luka, and Angeline)</p>
                                        <Video2/>
                                        <div className="divider"/>
                                        <p className='theater-subtitle'>
                                        Incomming Transmission</p>
                                        <Video3/>
                                        <div className="divider"/>
                                        <p className='theater-subtitle'>
                                        Making of Snippet - Backgrounds</p>
                                        <Making1/>
                                        <div className="divider"/>
                                        <p className='theater-subtitle'>
                                        Making of Snippet - Heads and Bodies</p>
                                        <Making2/>
                                        <div className="divider"/>
                                        <p className='theater-subtitle'>
                                        Making of Snippet - The Happy Accident</p>
                                        <Making3/>
                                        <div className="divider"/>
                                        <p className='theater-subtitle'>
                                        Sidestory - Comfort</p>
                                        <Comfort/>
                                        <div className="divider"/>
                                        <p className='theater-subtitle'>
                                        The Eldritch Sprites</p>
                                        <img src={ftm} alt="eldritch rising sprite ftm fantom opera" style={{ width: '200px', justifyContent: 'center', paddingRight: '20px' }} />
                                        <img src={avax} alt="eldritch rising sprite avax avalanche" style={{ width: '200px', justifyContent: 'center', paddingRight: '20px' }} />
                                        <img src={eth} alt="eldritch rising eth ethereum" style={{ width: '200px', justifyContent: 'center', paddingRight: '20px'  }} />
                                        <img src={hbar} alt="eldritch rising hbar hedera hashgraph" style={{ width: '200px', justifyContent: 'center', paddingRight: '20px' }} />
                                        <div className="divider"/>
                                        <p className='theater-subtitle'>
                                        The Seeds of Change</p>
                                        <img src={plant01} alt="seeds of change 01" style={{ width: '200px', justifyContent: 'center', paddingRight: '20px' }} />
                                        <img src={plant02} alt="seeds of change 02" style={{ width: '200px', justifyContent: 'center', paddingRight: '20px'  }} />
                                        <img src={plant03} alt="seeds of change 03" style={{ width: '200px', justifyContent: 'center', paddingRight: '20px' }} />
                                        <div className="divider"/>
                                        <p className='theater-subtitle'>
                                        The Legendary Sprites</p>
                                        <img src={lovers} alt="seeds of change 01" style={{ width: '200px', justifyContent: 'center', paddingRight: '20px' }} />
                                        <img src={scholar} alt="seeds of change 02" style={{ width: '200px', justifyContent: 'center', paddingRight: '20px'  }} />
                                        <img src={guardian} alt="seeds of change 03" style={{ width: '200px', justifyContent: 'center', paddingRight: '20px' }} />
                                        <img src={necromancer} alt="seeds of change 01" style={{ width: '200px', justifyContent: 'center', paddingRight: '20px' }} />
                                        <img src={oracle} alt="seeds of change 02" style={{ width: '200px', justifyContent: 'center', paddingRight: '20px'  }} />
                                        <img src={follower} alt="seeds of change 03" style={{ width: '200px', justifyContent: 'center', paddingRight: '20px' }} />
                                        <img src={chaos} alt="seeds of change 03" style={{ width: '200px', justifyContent: 'center', paddingRight: '20px' }} />
                                        <div className="divider"/>
                                        <p className='theater-subtitle'>
                                        Mint-Out Celebratory Drops</p>
                                        <img src={genesis} alt="seeds of change 03" style={{ width: '200px', justifyContent: 'center', paddingRight: '20px' }} />
                                        <img src={keeper} alt="seeds of change 03" style={{ width: '200px', justifyContent: 'center', paddingRight: '20px' }} />
                                        <div className="divider"/>
                                        <div className='theater-subtitle'>
                                        <p>Thank You. All of you. What a journey it&apos;s been, with so many Eldritch stories still to tell.</p><br/><br/>
                                        <p>Eldritch Rising is a story told through various forms of art on multiple blockchains. Currently, Eldritch Rising Sprites exist on FTM,
                                        AVAX, and ETH with 1:1 pieces living on ETH, FTM, Hedera, and AVAX. Eldritch Rising is a story told through the expression of art, where
                                        holders get to learn details of the mysterious story through airdropped pieces, clues within metadata, exclusive access to new collections,
                                        gamified dApps, and more.
                                        <br/><br/>
                                        Most recently, the Eldritch Sprites have evolved with the help of Stable Diffusion. The artist and dev behind the collection,
                                        <a className="link" href="https://twitter.com/edgetechnician" target={"_blank"} rel={"noopener noreferrer"}> edgetechnician</a>,
                                        spent hundreds of hours designing custom traits and training AI models to create a representation of the universe that is
                                        both entirely unique and compelling enough to move the brand further. Holders of Eldritch Rising EVOLVED will receive many
                                        benefits as the universe expands into new verticals.</p>
                                        <br/><br/>
                                        </div>

                                        <div className="divider"/>
                                       <div className="divider"/>
                                    </div>
                            </div>
                            <div className="divider"/>
                        </div>

                <div style={{ display: 'flex'}}>
                    <button className='buttonBackToMenu' onClick={() => window.location.replace(`/`)}>MAIN MENU</button>
                </div>
            </div>
        )
    }
}

export default Theater;
