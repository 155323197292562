import 'animate.css';
import React, { useEffect, useState } from 'react';
import './styles/Home.css';
import './styles/index.css';

function Open() {

    const [loading, setLoading] = useState(true);
    const [toggleEffect, setToggleEffect] = useState(true);
    const [seeMore, setSeeMore] = useState(false);

    const loadData = async () => {
        await new Promise((r) => setTimeout(r, 1000));
        setLoading((loading) => !loading);
    };

    useEffect(() => {
        loadData();
    }, [])

    const handleClickSeeMore = () => {
        setSeeMore(!seeMore)
    }

    const carryOn = () => {
      const { href } = window.location;
      window.location.href = `/Open2`;
}

    if (loading) {
        return (
            <div className='homepage crt'>
                <h3 className='loadingInterlude'>LOADING...</h3>
            </div>
        )
    } else {
        return (
            <div className={toggleEffect ? 'homepage crt' : 'homepage'}>
                <div className='submenu-about scroll'>
                    <div>
                        <div className='eldritch-container'>
                            <span className='eldritch-title'>ITS OVER</span>
                            <br />
                            <p className='eldritch-subtitle'>
                            ** CLASSIFIED INFORMATION **
                            </p>
                            <br />
                            Our entire cybernetwork has fallen. Somehow, THE ELDRITCH have gained access to THE ARCHITECT and are systematically eradicating all of the data within.
                            <br />
                            <br />
                            They are erasing our very existence. Our pasts. Our present. Any hope we had of a future.
                            <br />
                            <br />
                            This is really it, isn&apos;t it?
                            <br />
                                    <div>
                                        <br/>
                                        <button className="buttonWeb3" onClick={() => window.location.replace(`/Open3`)}>yEs</button>
                                        <button className="buttonWeb3" onClick={() => window.location.replace(`/Open3`)}>YeS</button>
                                        <button className="buttonWeb3" onClick={() => window.location.replace(`/Open3`)}>yES</button>
                                    </div>
                                    <div className="divider"/>
                        </div>
                    </div>


                </div>
                <div style={{ display: 'flex'}}>
                    <button className='buttonBackToMenu' onClick={() => window.location.replace(`/archive`)}>ARCHIVE MENU</button>
                </div>
                <div className="divider"/>
            </div>
        )
    }
}

export default Open;
