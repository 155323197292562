import React, { useEffect, useRef, useCallback } from "react";
import './styles/Home.css';

const ArchiveItem = ({ character, focus, index, setFocus }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (focus) {
            // Move element into view when it is focused
            ref.current.focus();
        }
    }, [focus]);

    const handleSelect = useCallback(() => {
        if (index === 0) {
            window.location.replace(`/eldritch`);
        }
        else if (index === 1) {
            window.location.replace(`/hope`);
        }
        else if (index === 2) {
            window.location.replace(`/contact`);
        }
        else if (index === 3) {
            window.location.replace(`/footage`);
        }
        else if (index === 4) {
            window.location.replace(`/cosmos`);
        }
        else if (index === 5) {
            window.location.replace(`/soonTM`);
        }
        else if (index === 6) {
            window.location.replace(`/Open`);
        }
        else if (index === 7) {
            window.location.replace(`/menu`);
        }


        // setting focus to that element when it is selected
        setFocus(index);
    }, [character, index, setFocus]);

    return (
        <li
            tabIndex={focus ? 0 : -1}
            role="button"
            ref={ref}
            onClick={handleSelect}
            onKeyPress={handleSelect}
            className="link"
        >
            {character}
        </li>
    );
};

export default ArchiveItem;
