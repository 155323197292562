import 'animate.css';
import React, { useEffect, useState } from 'react';
import './styles/Home.css';
import './styles/index.css';
import sample01 from './images/sample01.png';
import sample02 from './images/sample02.png';
import sample03 from './images/sample03.png'

function Cosmos() {

    const [loading, setLoading] = useState(true);
    const [toggleEffect, setToggleEffect] = useState(true);
    const [seeMore, setSeeMore] = useState(false);

    const loadData = async () => {
        await new Promise((r) => setTimeout(r, 1000));
        setLoading((loading) => !loading);
    };

    useEffect(() => {
        loadData();
    }, [])

    const handleClickSeeMore = () => {
        setSeeMore(!seeMore)
    }

    if (loading) {
        return (
            <div className='homepage crt'>
                <h3 className='loadingInterlude'>LOADING...</h3>
            </div>
        )
    } else {
        return (
            <div className={toggleEffect ? 'homepage crt' : 'homepage'}>
                <div className='submenu-about scroll'>
                    <h3 className='header-home'>**** EDGETECH.INDUSTRIES ****</h3>
                    <div>
                        <div className='eldritch-container'>
                            <span className='eldritch-title'>COSMOS</span>
                            <br />
                            <p className='eldritch-subtitle'>
                            ** CLASSIFIED INFORMATION **
                            </p>
                            <br />
                            They say the CATASTROPHE descended from the heavens and laid waste to everything.
                            <br /> <br/>
                            There wasn&apos;t much left for them to eradicate, but that&apos;s beside the point.
                            <br/><br/>
                            I am struggling to comprehend a magical rock traversing the cosmos for God knows how long,
                            only to collide with our planet in an attempt to take it over.
                            <br/><br/>
                            The organisms growing from the imapact site, they&apos;re not extraterrestrial. All of this growth we see on the surface
                            are of species native to Earth. The only thing that seems out of place are these ELDRITCH creatures.
                            <br/><br/>
                            I find it very hard to believe that aliens have come to seize our planet, yet not a single piece of flora or fauna
                            brought with them is unknown to science.
                            <br/><br/>
                            Something simply does not add up.
                            <br/><br/>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex'}}>
                    <button className='buttonBackToMenu' onClick={() => window.location.replace(`/archive`)}>ARCHIVE MENU</button>
                </div>
            </div>
        )
    }
}

export default Cosmos;
