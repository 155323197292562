import './styles/App.css';
import Board from './components/Board';
import Modal from './components/Modal/Modal';
import React, { useState } from "react";
import help from '././images/help.png';

const toMenu = () => {
  const { href } = window.location;
  window.location.href = `/archive`;
}

const toReload = () => {
  window.location.reload();
}

function App() {

  const [isRulesOpen, setIsRulesOpen] = useState(false);
  const [isClaimOpen, setIsClaimOpen] = useState(false);
  const [isClueOpen, setIsClueOpen] = useState(false);

  const claimModal = () => {
    if (isClaimOpen == true){

    }
  }

  const rulesModal = () => {
    if (isRulesOpen == true) {
      const title = <div className="gradient-text-blue">**** RULES ****</div>
      const content = (
        <>
        <div>
        Greetings, Technician.
        <br/><br/>
        Your objective is simple. To purge anomolies in the system, you must ensure that all cells on the data grid are powered down.
        <br/><br/>
        Clicking on a cell causes the target and all adjacent cells to swap states.
        <br/><br/>
        Use this to your advantage to power-down all the lit cells on the grid.
        <br/><br/>
        If you are struggling, click NEW ANOMOLY to reset the grid with a new set of lit cells.
        <br/><br/>
        Good luck.
        <br/>
        </div>
      </>
    );

      return (
        <Modal
          isOpen={isRulesOpen}
          onClose={() => setIsRulesOpen(false)}
          title={title}
          content={content}
        />
      )
    }
  }

  const clueModal = () => {
    if (isClueOpen == true) {
      const title = <div className="gradient-text-blue">**** HELP ****</div>
      const content = (
        <>
        <div>
        <br/><br/>
        nOt AlL PaThs LeaD to ProGrEsS
        <img src={help}/>
        <br/><br/>
        fLoAt dOwn thE RivEr oF mIsT
        <br/><br/>
        leT thE sEeDs oF cHanGe tRicKlE doWnsTrEaM
        <br/><br/>
        sTaRt wiTh a CLeAr HeAd
        <br/><br/>
        rInSe, thEn rEPeaT
        <br/><br/>
        </div>
      </>
    );

      return (
        <Modal
          isOpen={isClueOpen}
          onClose={() => setIsClueOpen(false)}
          title={title}
          content={content}
        />
      )
    }
  }

  return (
    <div className="homepage crt">
      {rulesModal()}
      {clueModal()}
      <h4>Purge Anomolies to Proceed</h4>
      <button className='buttonBackToMenu' onClick={() => setIsRulesOpen(true)}>RULES</button>
      <br/>
      <Board size={5} chanceLightStartsOn={0.25} />
      <div style={{ display: 'flex'}}>
      <button className='buttonBackToMenu' onClick={toReload}>New Anomoly</button>
      <button className='buttonBackToMenu' onClick={toMenu}>Give Up</button>
      <button className='buttonBackToMenu' onClick={() => setIsClueOpen(true)}>I&apos;m Stuck!</button>
      </div>
    </div>
  );
}

export default App;
