import { Modal as BSModal } from "react-bootstrap";
import classes from "./Modal.module.css";

const Modal = ({
    isOpen,
    onClose,
    title,
    content
}) => {

    return (
        <BSModal
            show={isOpen}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            className={classes.modal}
        >

                <BSModal.Title className={classes.title}>{title}</BSModal.Title>
            <BSModal.Body className={classes.body}>
                {content}
                <button className={classes.buttonBackToMenu} onClick={onClose}>
                    Close
                </button>
            </BSModal.Body>
        </BSModal>
    )

}

export default Modal;
